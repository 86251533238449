<template>
    <div class="goods-v4-container">
        <div class="goods-cover">
            <img :src="imgUrl"/>
        </div>
        <div class="goods-info">
            <div class="goods-title">{{title}}</div>
            <div class="goods-status-text" v-if="statusText">{{statusText}}</div>
            <div class="number">x{{number}}</div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'GoodsV4',
    props: {
      imgUrl: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      number: {
        type: [String,Number],
        default: 1
      },
      statusText:{
        type:String,
        default:''
      }
    }
  }
</script>

<style scoped lang="scss">
    .goods-v4-container {
        width: 100%;
        display: flex;

        .goods-cover {
            width: 80px;
            height: 80px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            margin-right: 9px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .goods-info {
            flex: 1;

            .goods-title {
                width: 100%;
                height: 32px;
                line-height: 16px;
                font-size: 14px;
                color: #333333;
            }

            .number {
                height: 24px;
                display: flex;
                align-items: flex-end;
                color: #939393;
                font-size: 14px;
            }

            .goods-status-text{
                font-size: 14px;
                color: #108ee9;
                display: flex;
            }
        }
    }
</style>